html,
body {
  color: #222e3c;
  position: relative;
  max-width: 100%;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Raleway' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: 'Raleway';
  font-weight: bold;
  letter-spacing: 2px;
}

@font-face {
  font-family: 'Chalkduster';
  src: url('../assets/fonts/Chalkduster.woff2') format('woff2'),
    url('../assets/fonts/Chalkduster.woff') format('woff'),
    url('../assets/fonts/Chalkduster.ttf') format('truetype');
}

.swiper-container {
  height: 100%;
}

.swiper-wrapper {
  height: 100%;
}

a {
  color: #222e3c;
}
a:hover {
  color: #078080;
}
